<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container"  style="position: relative;">
        <base-search :searchList="$jczlPlclsbSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;" @handleCommand="handleCommand" :dynamic='true' @operationChoose="operationChoose" :isPlclsb="true"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <!-- 拖拽div -->
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容-->
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" @nodeClickOne="nodeClickOne"></basic-tree >
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容-->
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" :hasSelection="true" @handleSelectionChange="handleSelectionChange" :sepcialExchange="true"></basic-table >
            
          </div>
        </div>
      </div>
      <!-- 拖拽div -->
    </div>


    <!-- 添加弹窗 -->
    <!-- <general-form :FormDialog="FormDialog" :FormList="FormList" :title="addTitle" :FormData="addform"  @cancelDialog="cancelDialog" @confirmDialog="confirmDialog"></general-form> -->

    <!-- 指令弹窗 -->
    <el-dialog class="dialog-mini user-dialog" :title="instructionTitle" :visible.sync="instructionDialog" width="20%">
      <div>
        <el-input v-model="addform.belongArea"></el-input>
      </div>
      <div style="text-align:right;" slot="footer">
        <el-button size="small" type="cancel" @click="instructionDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="instructionDialogConfirm">确定</el-button>
      </div>
    </el-dialog>

    <!-- 定时采集弹窗 -->
    <general-form :FormDialog="collectDialog" :FormList="collectFormList" title="绑定定时任务" :FormData="collectForm"  @cancelDialog="cancelDialogCollect" @confirmDialog="confirmDialogCollect" :isDsrw="true" :isSpanAll="true"></general-form>
  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybqygl',
    components: {
      Sticky,
      generalForm
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          TreeIds:[],
          queryType:'0',//查询方式
          queryKey:'UserNumber',//用户编号
          queryValue:'',
          billingType:'0',//计费方式
        },
        orgs: [], // 用户可访问到的组织列表
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        FormDialog:false,//添加弹窗
        addform:{
          belongArea:'',
          areaName:'',
          areaAddress:'',
          areaNum:'',
          equipment:'0'
        },
        FormList:{//表格列
          column:[
            {columnKey:'belongArea',columnDescription:'归属区域',columnType:'text',prop:'belongArea',width:'120',placeholder:"归属区域",},
            {columnKey:'areaName',columnDescription:'区域名称',columnType:'text',prop:'areaName',width:'120',placeholder:"区域名称",},
            {columnKey:'areaAddress',columnDescription:'区域地址',columnType:'text',prop:'areaAddress',width:'120',placeholder:"区域地址",},
            {columnKey:'areaNum',columnDescription:'区域负责人编号',columnType:'text',prop:'areaNum',width:'120',placeholder:"区域负责人编号",},
            {
              columnKey:'equipment',
              columnDescription:'挂载设备',
              columnType:'select',
              placeholder:"请选择挂载设备",
              prop:'equipment',
              width:'120',
              seleceOption:[
                {label:'否',value:'0'},
                {label:'是',value:'1'},
              ],
              typeId:'Hyb_HaveDevice',//从全局数据字典里获取下拉数据
            },
          ],
          //校验
          rule:{
            belongArea: [{ required: true, message: "归属区域不能为空", trigger: "blur" }, ],
          }
        },
        addTitle:'添加',//添加弹窗标题
        instructionTitle:'',//指令弹窗标题
        instructionDialog:false,//指令弹窗控制
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
        chooseList:[],//多选数据
        collectDialog:false,//定时采集
        collectFormList:{//定时采集表格列
          column:[
            {
              columnKey:'taskId',
              columnDescription:'定时任务',
              columnType:'selectTimeTask',
              placeholder:"请选择定时任务",
              prop:'taskId',
              width:'120',
            },
          ],
          //校验
          rule:{
            // taskName: [{ required: true, message: "任务名称不能为空", trigger: "blur" }, ],
            taskId: [{ required: true, message: "定时任务不能为空", trigger: "blur" }, ],
          }
        },
        collectForm:{//定时采集from
          taskId:'',//任务id
        },
      }
    },
    watch: {
      
    },
    computed: {
      // orgsTree() {
      //   return this.$store.state.AllArea;
      // },
    },
    filters: {
      
    },
    created() {
      this.getList()
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
        // 计算table高度
      })

      this.$basicFun.dragControllerDiv();//拖拽div方法
    },
    methods: {
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        console.log(data)
      },
      //节点点击
      nodeClickOne(data){
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(data.id)
        this.getList()
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
            if(this.chooseList.length != 1){
              this.$message.error('请选择一个设备进行操作！');
              return
            }
            this.handleDelete()
            break
          case 'btnAdd'://添加
            this.FormDialog = true
            break
          case 'btnCollect'://定时采集
            if(this.chooseList.length <= 0){
              this.$message.error('请选择设备进行操作！');
              return
            }
            this.collectDialog = true
            break
          default:
            break
        }
      },
      // 下拉菜单事件
      handleCommand(val){
        if(this.tableChooseRow.serialNumber){
          this.instructionTitle = val.parameter
          this.instructionDialog = true;
          switch (val.parameter) {
            case '设置底数':
              
              break;
            case '设置表地址':
              
              break;
            case '设置上报周期':
              
              break;
            case '设置上报时间':
              
              break;
            case '设置冻结频率':
              
              break;
            case '设置密集冻结频率':
              
              break;
            case '设置无流量告警时间':
              
              break;
            case '设置过量自动关阀':
              
              break;
            case '开阀':
              
              break;
            case '关阀':
              
              break;
            case '阀门摆动':
              
              break;
            default:
              break;
          }
          // this.$message('click on item ' + val.command);
        }else{
          this.$message.error('请选择一个表进行操作！');
        }
        
      },
      // 操作指令
      operationChoose(command,operation1,judgeFrom){
        // command:allArea-按所有区域,chooseArea-按所选区域,chooseEquipment-按所选设备
        if(command == 'chooseArea' && this.listQuery.TreeIds.length <= 0){
          this.$message.error('请选择区域');
          return
        }
        if(command == 'chooseEquipment' && this.chooseList.length <= 0){
          this.$message.error('请选择设备');
          return
        }
        if(operation1){
          // this.$message('click on item ' + operation1);
          // return
          var meterIds = []
          this.chooseList.forEach(i=>{
            meterIds.push(i.meterId)
          })
          var keyValues,propertyValue,propertyValueTime
          if(operation1 == 'Magnification'){//倍率
            propertyValue = judgeFrom.Magnification
            keyValues=[{propertyName:operation1,propertyValue:propertyValue}]
          }
          if(operation1 == 'MeterBaseNumberGetType'){//表底数
            propertyValue = judgeFrom.MeterBaseNumberGetType
            propertyValueTime = this.$basicFun.dataFormat(judgeFrom.MeterBaseNumberSetTime,'yyyy-MM-dd');
            keyValues=[
              {
                propertyName:operation1,
                propertyValue:propertyValue
              },
              {
                propertyName:'MeterBaseNumber',
                propertyValue:judgeFrom.MeterBaseNumber
              },
              {
                propertyName:'MeterBaseNumberSetTime',
                propertyValue:propertyValueTime
              },
              
            ]
          }
          if(operation1 == 'ValveControlShow'){//阀控展示
            propertyValue = judgeFrom.ValveControlShow
            keyValues=[{propertyName:operation1,propertyValue:propertyValue}]
          }
          if(operation1 == 'IsWarning'){//是否告警
            propertyValue = judgeFrom.IsWarning
            if(judgeFrom.IsWarning == '0'){
              keyValues=[{propertyName:operation1,propertyValue:propertyValue}]
            }else{
              keyValues=[
                {
                  propertyName:operation1,
                  propertyValue:propertyValue
                },
                {
                  propertyName:'WarningType',
                  propertyValue:judgeFrom.WarningType
                },
                {
                  propertyName:'WarningTopLimit',
                  propertyValue:judgeFrom.WarningTopLimit
                },
                {
                  propertyName:'WarningLowerLimit',
                  propertyValue:judgeFrom.WarningLowerLimit
                },
              ]
            }
          }
          if(operation1 == 'ConsumptionCalculationMethod'){//用量计算方式
            propertyValue = judgeFrom.ConsumptionCalculationMethod
            keyValues=[{propertyName:operation1,propertyValue:propertyValue}]
          }
          if(operation1 == 'EquipmentMaximum'){//设备最大值
            propertyValue = judgeFrom.EquipmentMaximum
            keyValues=[{propertyName:operation1,propertyValue:propertyValue}]
          }
          if(operation1 == 'TypeSpecification'){//类型规格
            propertyValue = judgeFrom.TypeSpecification
            keyValues=[{propertyName:operation1,propertyValue:propertyValue}]
          }
          if(operation1 == 'ValveControlSupport'){//阀控支持
            propertyValue = judgeFrom.ValveControlSupport
            keyValues=[{propertyName:operation1,propertyValue:propertyValue}]
          }

          var areaIds =[]
          if(command == 'allArea'){
            this.$store.state.AllArea.forEach(i=>{
              areaIds.push(i.id)
            })
          }else if(command == 'chooseArea'){
            areaIds = this.listQuery.TreeIds
          }else{
            areaIds =[]
          }
          var data ={
            areaIds:areaIds,
            meterIds:meterIds,
            keyValues:keyValues,
          }
          basicDataApi.plclsbOperation(data).then(response => {
            if(response.code == 200){
              this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
              this.getList()
            }else{
              this.$notify({
                message: response.message,
                type: 'warning',
                duration: 2000
              });
            }
          })
        }else{
          this.$message.error('请选择操作条件');
        }
      },
      // 指令弹窗确认
      instructionDialogConfirm(){
        this.instructionDialog = false;
        this.$message.success('操作成功');
      },
      // 获取数据
      getList() {
        this.loading = true
        basicDataApi.plclsbGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.firstHeaderList.forEach(i=>{
              if(i.key == 'surfaceNum'){
                i.columnWidth = 160
              }
            })
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      //多选
      handleSelectionChange(val){
        this.chooseList = val
      },
      handleDelete(row) { // 多行删除
        // this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
      },
      // add弹窗取消
      cancelDialog(val){
        this.FormDialog = val
      },
      // add弹窗提交
      confirmDialog(){
        this.$store.state.tableDataSearch.push(this.addform)
        this.addform={
          name:'',
          type:'plbz',
          uploadFilsName:'',//上传文件名
        }
        this.FormDialog = false
      },
      // 定时采集弹窗取消
      cancelDialogCollect(val){
        this.collectDialog = val
      },
      // 定时采集弹窗提交
      confirmDialogCollect(){
        var ids = []
        this.chooseList.forEach(i=>{
          ids.push(i.meterId)
        })
        var data={
          taskId:this.collectForm.taskId,//任务id
          surfaceIds:ids
        }
        
        basicDataApi.plclsbTimeCollect(data).then(response => {
          if(response.code == 200){
            this.collectDialog = false
            this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        })
        
      },
    },
  }

</script>

<style lang="scss" scoped>
  
</style>
